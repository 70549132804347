import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import {
    MDBNavbarBrand,
    MDBCollapse,
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBContainer,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBNavbarLink,
    MDBCol,
    MDBFooter,
    MDBIcon,
    MDBRow,
    MDBBtn,
    MDBCard,
    MDBCardFooter,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCarousel,
    MDBCarouselItem,
    MDBBadge,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCardImage,
    MDBCardHeader,
    MDBAccordion,
    MDBAccordionItem,
} from 'mdb-react-ui-kit';
import './App.css';


function App() {
    const [openBasic] = useState(false);

    const [header, setHeader] = useState("header");

    const listenScrollEvent = () => {
        if (window.scrollY < 5) {
            return setHeader("header3");
        } else if (window.scrollY > 0) {
            return setHeader("header2");
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);
    return (
        <MDBContainer fluid>
            <header>
                {/* Main Navigation */}
                <MDBNavbar expand='lg' dark fixed='top' scrolling className={header}>
                    {/* Container wrapper */}
                    <MDBContainer sm>

                        <MDBNavbarBrand href='#'>
                            AVision World
                        </MDBNavbarBrand>

                        <MDBCollapse navbar open={openBasic}>
                            <MDBNavbarNav right className='mb-2 mb-lg-0'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink className='header-3' onClick={() => window.location.replace("/#product")}>
                                        <nav id="applications">
                                            Product Overview
                                        </nav>
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBNavbarLink>
                                                <nav id="applications">
                                                    <MDBDropdownToggle tag='a' role='button' className='header-3'>
                                                        AI Features
                                                    </MDBDropdownToggle>
                                                </nav>
                                            </MDBNavbarLink>
                                        </nav>
                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Forecasting Engine</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Recommendation Engine</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Classification</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Detection</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink >
                                        <nav id="home">
                                            <NavLink to='https://docs.avision.world/' target="_blank" rel="noopener noreferrer" className='header-3'>
                                                Documentation
                                            </NavLink>
                                        </nav>
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem className='header-3'>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link header-3' role='button'>
                                                About Us
                                            </MDBDropdownToggle>
                                        </nav>

                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem onClick={() => window.location.replace("/#section1")} to='/#section1' link>About Us</MDBDropdownItem>
                                                <MDBDropdownItem onClick={() => window.location.replace("/#section1")} to='/#section1' link>Contact Us</MDBDropdownItem>
                                                <MDBDropdownItem onClick={() => window.location.replace("/#pricing")} to='/#pricing' link>Pricing Guid</MDBDropdownItem>
                                                <MDBDropdownItem onClick={() => window.location.replace("/#section1")} to='/#section1' link>FAQ</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>

                        <MDBNavbarNav className='d-flex flex-row' right fullWidth={false}>
                            <NavLink to='https://outlook.office.com/bookwithme/user/fff8cf66d34b4a059a366baa2e6cfa5d@avisionsoftware.com?anonymous&ep=plink' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                <MDBBtn tag="a" outline size="lg" >
                                    <MDBIcon fab icon="microsoft" /> Request Demo
                                </MDBBtn>
                            </NavLink>
                            {/* <Notifications />
                            <LanguageSelect />
                            <Avatar /> */}
                            <NavLink to='https://app.avision.world' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                <MDBBtn tag="a" outline size="lg" >
                                    <MDBIcon fas icon="user" /> Client Portal
                                </MDBBtn>
                            </NavLink>
                        </MDBNavbarNav>
                    </MDBContainer>
                </MDBNavbar>


            </header>


            <div
                className='p-5 text-center bg-image'
                style={{ backgroundImage: "url('/images/header.webp')", height: '100vh', padding: '0px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>Welcome to AVision.World</h1>
                            <h4 className='mb-3'>Transform Data into Actionable Insights with AI-Powered Analytics</h4>
                            <MDBRow className='d-flex justify-content-center'>
                                <MDBCol size='md-2'>
                                    <MDBCard alignment='center' border='primary' background='transparent'>
                                        <MDBCardHeader className="header-1">Data</MDBCardHeader>
                                        <MDBCardBody>
                                            <MDBCardTitle className="header-4">Unlock predictive trends and patterns for smarter decision-making</MDBCardTitle>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='md-2'>
                                    <MDBCard alignment='center' border='primary' background='transparent'>
                                        <MDBCardHeader className="header-1">Video</MDBCardHeader>
                                        <MDBCardBody>
                                            <MDBCardTitle className="header-4">Analyze footage for real-time monitoring, behavior analysis, and security</MDBCardTitle>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='md-2'>
                                    <MDBCard alignment='center' border='primary' background='transparent'>
                                        <MDBCardHeader className="header-1">Image</MDBCardHeader>
                                        <MDBCardBody>
                                            <MDBCardTitle className="header-4">Automate visual recognition, quality, and content curation</MDBCardTitle>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='md-2'>
                                    <MDBCard alignment='center' border='primary' background='transparent'>
                                        <MDBCardHeader className="header-1">Audio</MDBCardHeader>
                                        <MDBCardBody>
                                            <MDBCardTitle className="header-4">Transcribe, analyze sentiment, and extract key insights</MDBCardTitle>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>


                        </div>
                    </div>
                </div>
            </div>
            <div id="platform" className='p-1 mb-4'>
                {/* <Helmet>
                    <title>AVision Software: Realize Value with AI</title>
                    <meta name="description" content="AVision Software: Realize Value with AI" />
                    <meta name="keywords" content="AVision Software: Realize Value with AI" />
                    <meta name="author" content="AVision" />
                    <meta property="og:title" content="AVision Software: Realize Value with AI" />
                    <meta property="og:description" content="AVision Software: Realize Value with AI" />
                    <meta property="og:image" content="https://example.com/image.jpg" />
                    <meta property="og:url" content="https://example.com/my-page" />
                    <meta name="twitter:title" content="AVision Software: Realize Value with AI" />
                    <meta name="twitter:description" content="AVision Software: Realize Value with AI" />
                    <meta name="twitter:image" content="https://example.com/image.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet> */}
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody id='product'>
                                <MDBCardTitle>
                                    <p className="header-1">data-driven insights across industries</p>
                                    <MDBRow className='d-flex justify-content-center'>
                                        <MDBCol size='md-2'>
                                            <MDBCard shadow='0' border='primary'>
                                                {/* <MDBCardImage src='/images/real-estate.avif' alt='...' position='top' /> */}
                                                <MDBCardBody>
                                                    <MDBCardText className='header-2'>
                                                        <MDBIcon fas icon="home" /> PropTech
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                        <MDBCol size='md-2'>
                                            <MDBCard shadow='0' border='primary' >
                                                {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                                <MDBCardBody>
                                                    <MDBCardText className='header-2'>
                                                        <MDBIcon fas icon="hotel" /> ResortTech
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                        <MDBCol size='md-2'>
                                            <MDBCard shadow='0' border='primary' >
                                                {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                                <MDBCardBody>
                                                    <MDBCardText className='header-2'>
                                                        <MDBIcon fas icon="university" /> FinTech
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                        <MDBCol size='md-2'>
                                            <MDBCard shadow='0' border='primary'>
                                                {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                                <MDBCardBody>
                                                    <MDBCardText className='header-2'>
                                                        <MDBIcon fas icon="hospital" /> HealthTech
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                        <MDBCol size='md-2'>
                                            <MDBCard shadow='0' border='primary'>
                                                {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                                <MDBCardBody>
                                                    <MDBCardText className='header-2'>
                                                    <MDBIcon fas icon="solar-panel" /> EnergyTech
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardTitle>
                                <MDBCardText className="header-text-align font-color">
                                    <hr className="hr" />
                                    <h6 className="header-1">Key Features</h6>
                                    <MDBAccordion initialActive={1}>
                                        <MDBAccordionItem btnClassName='header-2' collapseId={1} headerTitle='Multi-Modal Analytics'>
                                            <p className='header-4'>Analyze structured data alongside video, image, and audio for more nuanced insights.</p>
                                        </MDBAccordionItem>
                                        <MDBAccordionItem btnClassName='header-2' collapseId={2} headerTitle='Industry-Specific Dashboards'>
                                            <p className='header-4'>Tailored to meet the unique needs of sectors like real estate, healthcare, and retail, and more with pre-built dashboards and analytics tailored for each industry.</p>
                                        </MDBAccordionItem>
                                        <MDBAccordionItem btnClassName='header-2' collapseId={3} headerTitle='Automation & Optimization'>
                                            <p className='header-4'>Enhance efficiency through automated processes and AI-driven recommendations, whether for marketing campaigns, sales strategies, or operational workflows.</p>
                                        </MDBAccordionItem>
                                        <MDBAccordionItem btnClassName='header-2' collapseId={4} headerTitle='Real-Time Monitoring'>
                                            <p className='header-4'>Stay updated with real-time alerts and anomaly detection, ensuring you can respond quickly to changes in performance or risk factors.</p>
                                        </MDBAccordionItem>
                                        <MDBAccordionItem btnClassName='header-2' collapseId={5} headerTitle='Customizable Reports'>
                                            <p className='header-4'>Generate detailed reports with flexible export options and scheduled deliveries, ensuring stakeholders receive critical insights when needed.</p>
                                        </MDBAccordionItem>
                                        <MDBAccordionItem btnClassName='header-2' collapseId={6} headerTitle='Integration & Interoperability'>
                                            <p className='header-4'>Connect seamlessly with existing tools like CRM, ERP, and other data sources for a unified view of business performance.</p>
                                        </MDBAccordionItem>
                                    </MDBAccordion>
                                </MDBCardText>
                                < MDBCardTitle>
                                    <h6 className="header-1">NeuroSense AI Models are our collection of advanced AI models designed for AI Analytics</h6>
                                </MDBCardTitle>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCarousel showIndicators showControls fade>
                            <MDBCarouselItem itemId={1}>
                                <MDBCard className='mb-3'>
                                    <MDBCardImage position='top' src='/images/classification.jpg' alt='...' height='500px' />
                                    <MDBCardBody>
                                        <MDBCardTitle className='header-2'>Classification</MDBCardTitle>
                                        <MDBCardText className='header-4'>
                                            Classification is a type of supervised learning where the goal is to predict the categorical label of a given input based on its features. In classification tasks, the output variable is discrete, meaning it falls into one of several predefined categories or classes.
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={2}>
                                <MDBCard className='mb-3'>
                                    <MDBCardImage position='top' src='/images/detection.jpg' alt='...' height='500px' />
                                    <MDBCardBody>
                                        <MDBCardTitle className='header-2'>AI Detection</MDBCardTitle>
                                        <MDBCardText className='header-4'>
                                            Detection, often referred to as object detection in the context of computer vision, is the task of identifying and locating objects within an image or video. Unlike classification, detection involves both recognizing objects and determining their positions within the image.
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={3}>
                                <MDBCard className='mb-3'>
                                    <MDBCardImage position='top' src='/images/recommendation.webp' alt='...' height='500px' />
                                    <MDBCardBody>
                                        <MDBCardTitle className='header-2'>AI Recommendation</MDBCardTitle>
                                        <MDBCardText className='header-4'>
                                            Prediction is the process of using historical data and statistical or machine learning models to estimate future outcomes or trends. It involves making informed guesses about what will happen next based on patterns observed in past data.
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={4}>
                                <MDBCard className='mb-3'>
                                    <MDBCardImage position='top' src='/images/forecasting.jpeg' alt='...' height='500px' />
                                    <MDBCardBody>
                                        <MDBCardTitle className='header-2'>Forecasting</MDBCardTitle>
                                        <MDBCardText className='header-4'>
                                            Decision capabilities include everything from anomaly detection to automatic content personalization to automated content moderation for text, image, and video applications. Machine learning and advanced analytics are good examples of this domain.
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>

                            </MDBCarouselItem>
                        </MDBCarousel>
                        <MDBCard id='features'>
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-1">AI-Powered Solutions Across Departments</p></MDBCardTitle>
                                <hr className="hr" />
                                <MDBRow className='d-flex justify-content-center'>
                                    <MDBCol size='md-2'>
                                        <MDBCard shadow='0' border='primary'>
                                            {/* <MDBCardImage src='/images/real-estate.avif' alt='...' position='top' /> */}
                                            <MDBCardBody>
                                                <MDBCardText className='header-2'>
                                                    Marketing
                                                </MDBCardText>
                                                <MDBCardText className='header-5'>
                                                    Improve campaigns with AI-driven predictions, segmentation, and sentiment analysis.
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size='md-2'>
                                        <MDBCard shadow='0' border='primary' >
                                            {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                            <MDBCardBody>
                                                <MDBCardText className='header-2'>
                                                    Sales
                                                </MDBCardText>
                                                <MDBCardText className='header-5'>
                                                    Drive conversions with lead scoring, revenue forecasting, and opportunity prioritization.
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size='md-2'>
                                        <MDBCard shadow='0' border='primary' >
                                            {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                            <MDBCardBody>
                                                <MDBCardText className='header-2'>
                                                    Finance
                                                </MDBCardText>
                                                <MDBCardText className='header-5'>
                                                    Optimize budgeting, manage risks, and detect anomalies with predictive finance models.
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size='md-2'>
                                        <MDBCard shadow='0' border='primary'>
                                            {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                            <MDBCardBody>
                                                <MDBCardText className='header-2'>
                                                    HR
                                                </MDBCardText>
                                                <MDBCardText className='header-5'>
                                                    Enhance talent management with prediction and recruitment analytics
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol size='md-2'>
                                        <MDBCard shadow='0' border='primary'>
                                            {/* <MDBCardImage src='https://mdbootstrap.com/img/new/standard/nature/182.webp' alt='...' position='top' /> */}
                                            <MDBCardBody>
                                                <MDBCardText className='header-2'>
                                                    Support
                                                </MDBCardText>
                                                <MDBCardText className='header-5'>
                                                    Boost service quality with AI-powered ticketing, sentiment analysis, and chatbots
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>

                                </MDBRow>
                                <MDBCardText className="header-text-align font-color">

                                    <hr className="hr" />
                                    <h6 className='header-5'>Our platform’s user-friendly interface ensures that everyone, from data analysts to executives, can easily access the insights they need. By integrating AI at every level, we transform raw data into actionable strategies, helping businesses achieve measurable results.</h6>
                                    <h6 className='header-5'>Experience the future of analytics with a platform that doesn’t just track metrics but actively guides your next steps, making it an indispensable tool for data-driven decision-making.</h6>
                                </MDBCardText>

                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>

            <div id='pricing' className='p-1 mb-4'>
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-1">Pricing Guide</p></MDBCardTitle>
                                <hr className="hr" />
                                <MDBTable align='middle' className='header-4'>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'><p className='fw-bold mb-1'>Features</p></th>
                                            <th scope='col'><p className='fw-bold mb-1'><MDBBadge color='success' pill>Standard</MDBBadge></p></th>
                                            <th scope='col'><p className='fw-bold mb-1'><MDBBadge color='info' pill>Enterprise</MDBBadge></p></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        <tr>    
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <p className='fw-bold mb-1'>
                                                        Basic dashboard, Limited Data Sources
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="times" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <p className='fw-bold mb-1'>
                                                        Advanced AI models, Industry Templates
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>
                                                        AI In-App Copilot
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>
                                                        Unlimited Data, Premium Integrations
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>
                                                        Priority Support
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>
                                                        Multi Channel AI Copilot 
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>
                                                        WHite Labeling, Fully Customizable
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>
                                                        Dedicated Infrastructure
                                                   </p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                <p className='fw-bold mb-1'>Price per month</p><p className='header-5'> (**excludes setup and implementation fees)</p>
                                                </div>
                                            </td>
                                            <td>$999</td>
                                            <td>$1,999</td>
                                        </tr>
                                    </MDBTableBody>

                                </MDBTable>
                                <MDBCardFooter className="footer-align">
                                    <NavLink to='https://outlook.office.com/bookwithme/user/fff8cf66d34b4a059a366baa2e6cfa5d@avisionsoftware.com?anonymous&ep=plink' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        <MDBBtn tag="a" outline size="lg" >
                                            <MDBIcon fab icon="microsoft" /> Register Now
                                        </MDBBtn>
                                    </NavLink>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
            <div id='about' className='p-1 mb-4'>
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>
                                    <p className="header-1">About Us</p>
                                </MDBCardTitle>
                                <MDBCardText className="header-text-align font-color">
                                    <hr className="hr" />
                                    <h6 className='header-5'>We're at the forefront of the AI revolution, committed to developing state-of-the-art software solutions that redefine efficiency, creativity, and decision-making.
                                        Our technology is designed to enhance human potential and empower organizations across various industries. </h6>
                                    <h6 className='header-5'>Our approach is grounded in ethical principles, ensuring that our advancements contribute positively to society and the environment.</h6>
                                    <h6 className='header-5'>Our team combines deep expertise in AI, data analytics, and industry knowledge across key sectors like Real Estate, Healthcare, and Retail. With experience in building scalable solutions, we excel at turning complex data into actionable insights. Our unique blend of technical skills, domain expertise, and a proven track record of delivering innovative products positions us perfectly to create a platform that drives real impact and growth for our clients. We’re passionate about pushing the boundaries of AI to solve critical business challenges and deliver value at scale.</h6>
                                    <hr className="hr" />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBFooter className='text-center text-lg-start text-muted'>
                <section className=''>
                    <MDBContainer className='text-center text-md-start mt-5'>
                        <MDBRow className='mt-3'>
                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>
                                    <MDBIcon icon="gem" className="me-3" />
                                    AVision.World
                                </h6>
                                <p>
                                    AVision World is an Advanced AI Analytics Platform, focusing on Data, Audio, Image and Video Analytics.
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Features</h6>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        AI Data Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        AI Video Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='https://www.avision.world!' className='text-reset'>
                                        AI Image Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        AI Audio Analytics
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>AI Features</h6>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        Forecasting & Prediction
                                    </a>
                                </p>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        Recommendation Engine
                                    </a>
                                </p>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        Classification
                                    </a>
                                </p>
                                <p>
                                    <a href='https://www.avision.world' className='text-reset'>
                                        Detection
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Contact</h6>
                                <p>
                                    <MDBIcon icon="home" className="me-3" />Dubai , United Arab Emirates
                                </p>
                                <p>
                                    <MDBIcon icon="envelope" className="me-3" />register@avision.world
                                </p>
                                <p>
                                    <MDBIcon icon="phone" className="me-3" />+971 58 552 2636
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                    <div className='me-5 d-none d-lg-block'>
                        <span className="header-2">Get connected with us on social networks:</span>
                    </div>

                    <div className="header-2">
                        <NavLink to='https://wa.me/971585522636' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                            <MDBIcon fab icon="whatsapp" />
                        </NavLink>

                        <a href='https://twitter.com/SoftwareAvision' className='me-4 text-reset'>
                            <MDBIcon fab icon="twitter" />
                        </a>
                        <a href='https://www.youtube.com/@avisionsoftware4886' className='me-4 text-reset'>
                            <MDBIcon fab icon="google" />
                        </a>
                        <a href='https://www.instagram.com/avisionsoftware/' className='me-4 text-reset'>
                            <MDBIcon fab icon="instagram" />
                        </a>
                        <a href='https://www.linkedin.com/company/avisionsoftware/' className='me-4 text-reset'>
                            <MDBIcon fab icon="linkedin" />
                        </a>
                        <a href='https://github.com/AVision-Software' className='me-4 text-reset'>
                            <MDBIcon fab icon="github" />
                        </a>
                    </div>
                </section>
                <div className='text-center p-4 header-2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © 2024 Copyright:
                    <a className='text-reset fw-bold' href='http://www.avisionsoftware.com/'>
                        AVision.World
                    </a>
                </div>
            </MDBFooter>
        </MDBContainer>

    );
}

export default App;